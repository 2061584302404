@import "constant";

@media screen and (max-width: 1200px) {
  #mainContent {
    min-height: $contentSize;

    &.content-size-1 {
      overflow: auto;
      width: 100%;
      display: block;

      img {
        display: block;
        height: $contentSize;
        max-width: unset;
      }
    }
  }
}

@media screen and (max-width: 1195px) {
  .home {
    img {
      width: unset;
      height: $contentSize;
    }
  }
}

@media screen and (max-width: 960px) {
  .logo-container {
    visibility: hidden;
  }
}

@media screen and (max-width: 745px) {
  .logo-container {
    visibility: visible;
  }

  .header-expander {
    display: inline-block;
    float: right;
    position: fixed;
    right: 20px;
    transition: right 0.5s ease;
    top: 0;

    &.expanded {
      color: white;
      right: 60px;
    }
  }

  header {
    position: fixed;
    overflow: unset;

    &.hidden {
      height: 0;

      .logo-container {
        visibility: hidden;
      }
    }
  }

  nav {
    padding-top: 60px;
    line-height: 40px;
    width: 0;
    position: fixed;
    top: 0;
    background-color: #333333CC;
    height: 100vh;

    ul {
      flex-direction: column;
      padding-inline-start: 20px;
    }

    &.expanded {
      width: 160px;
    }
  }

  #mainContentContainer {
    margin-top: $headerHeight;
  }

  .bottom {
    padding: 0 40px;
  }
}
