@import "constant";

.bottom {
  min-height: $bottomHeight;
  padding: 0 60px;
  line-height: $bottomHeight;
  background-color: rgba(255, 255, 255, .8);
  display: flex;
  justify-content: space-between;
  flex-grow: 2;

  > * {
    align-self: center;
  }

  .btm-logo-container > img {
    height: $bottomHeight/2;
    vertical-align: middle;
  }

  .btm-info {
    font-size: 14px;

    a {
      color: #333333CC;
    }
  }


}
