@import "constant.scss";

.home {
  img {
    width: 100%;
    max-width: unset;
    min-height: $contentSize;
  }

  .slick-slide {
    > div > div {
      overflow: auto;
    }
  }
}

div.homepage {
  padding-top: 0;
}
